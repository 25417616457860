<template>
  <div class="service-box">
    <div class="w service ty300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/home-image/page29.png" alt="">
      </div>
      <div class="content">
        <div class="box">
          <div class="item" @mouseenter="mouseenter(index)" v-for="(item, index) in serviceList" :key="index" :class="{'active': activeIndex === index && activeIndex === 0,  'active2': activeIndex === index && activeIndex !== 0}">
            <div class="header">
              <div class="text" :class="[`text${index}`]">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="box2">
          <div class="cont" v-for="(item, index) in serviceList" :key="index"  @mouseenter="mouseenter(index)">
            <div class="row" :class="[`text${index}`]" v-for="(item2, index2) in item.subTitles" :key="index2">
              <div class="icon">
                <img :src="item.img" alt="">
              </div>
              <div class="text">{{ item2 }}</div>
            </div>
            <div class="btn curr" :class="[`text${index}`, activeIndex === index ? 'active': '']" @click="link(item.name)">
              案例详情>
            </div>
          </div>
        </div>
        <div class="phone-box">
          <div class="item2" v-for="(item, index) in serviceList" :key="index">
            <div class="header">
              <div class="title">{{ item.title }}</div>
            </div>
            <div class="cont">
              <div class="text" v-for="(item2, index2) in item.subTitles" :key="index2">{{ item2 }}</div>
            </div>
            <div class="btn curr" @click="link(item.name)">
              案例详情>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive, toRefs, watchEffect,
} from 'vue';
import { itemGsap } from '@/views/index/js/usePage';
import img from '@/assets/images/home-image/30.png';
import { useRouter } from 'vue-router';

export default {
  props: {
    currentPage: {
      type: Number,
      default: 0,
    },
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const data = reactive({
      serviceRef: null,
      count: 0,
      itemRefs: [],
      transformRef1: null,
      activeIndex: 0,
    });
    const serviceList = [
      {
        title: '冷链设计建造',
        subTitles: ['功能布局设计', '建造成本预算', '实操标准培训', '施工建造管理', '竣工验收管理'],
        className: ['tx900'],
        img,
        name: 'design',
      },
      {
        title: '冷链智慧维保',
        subTitles: ['远程技术支持', '系统运行评估', '维保配件集采', 'BF-ROM解决方案'],
        className: ['tx-900'],
        img,
        name: 'smartMaintenance',
      },
      {
        title: '供应链服务',
        subTitles: ['冷链运输和仓储服务', '客制化解决方案', '贸易服务', '金融支持', '冷链物流管理咨询', '招商运营顾问', '仓配一体中心', '食品质量检测与溯源中心'],
        className: ['tx1200'],
        img,
        name: 'coldChain',
      },
      {
        title: '冷链产业发展',
        subTitles: ['冷链产业咨询', '冷链产业开发', '冷链产业投资', '冷链资产管理', '冷链园区运营'],
        className: ['tx-1200'],
        img,
        name: 'operate',
      },
    ];

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 2,
        });
      }
    });

    const mouseenter = (index) => {
      data.activeIndex = index;
    };

    const link = (name) => {
      router.push({
        name,
      });
    };
    const mouseleave = () => {
      data.activeIndex = -1;
    };
    return {
      serviceList,
      ...toRefs(data),
      link,
      mouseenter,
      mouseleave,
    };
  },
};
</script>

<style scoped lang="scss">
.service-box {
  background-image: url('~@/assets/images/home-image/page28.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  height: 39.583vw;
  width: 100vw;
  .service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.none {
      opacity: 0;
    }
    .title-img {
      width: 21.51vw;
      height: 6.615vw;
      margin-top: 3.854vw;
      margin-bottom: 2.604vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      // border-radius: 2.083vw;
      // overflow: hidden;
      .box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-radius: 2.083vw;
        overflow: hidden;
        .text1,
        .text2 {
          margin-left: 3.125vw;
        }
      }
      .item {
        flex: 25%;
        overflow: hidden;
        .header {
          height: 3.958vw;
          text-align: center;
          line-height: 3.958vw;
          background-color: #D6D6D6;
          font-size: 1.146vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #090909;
          position: relative;
          transition: all 0.3s;
          overflow: hidden;
          &::before {
            content: '';
            position: absolute;
            width: 3.958vw;
            height: 3.958vw;
            background-color: #D6D6D6;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            left: -3.333vw;
            z-index: 10;
            border: 1.25vw solid #E9EAED;
            transition: all 0.3s;
          }
        }
        &.active .header{
          background-color: var(--baseColor1);
          color: #fff;
          &::before {
            background-color: var(--baseColor1);
            border: 1.25vw solid var(--baseColor1) !important;
          }
        }
        &.active2 .header{
          background-color: var(--baseColor1);
          color: #fff;
          &::before {
            background-color: var(--baseColor1);
            background-color: #D6D6D6 !important;
            border: 1.25vw solid #E9EAED !important;
          }
        }
        &.active + .item .header {
          &::before {
            background-color: var(--baseColor1);
          }
        }
        &.active2 + .item .header {
          &::before {
            background-color: var(--baseColor1);
          }
        }

        .cont {
          display: flex;
          flex-direction: column;
          align-items: center;
          .row {
            display: flex;
            .icon {
              width: 0.521vw;
              height: 0.521vw;
              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .text {
              font-size: 0.938vw;
              line-height: 1.875vw;
              color: #333333;
            }
          }
        }
      }
      .item:nth-child(1) {
        .header {
          &::before {
            border: 1.25vw solid #D6D6D6;
          }
        }
      }
      .box2 {
        display: flex;
        margin-top: 1.146vw;
        .cont {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-left: 6.25vw;
          .text1,
          .text2 {
            margin-left: 1.563vw;
          }
          .row {
            display: flex;
            margin-bottom: 0.625vw;
            .text {
              margin-left: 0.729vw;
              font-size: 0.833vw;
            }
            .text1,
            .text2 {
              margin-left: 3.125vw;
            }
          }
          .btn {
            width: 7.188vw;
            height: 2.396vw;
            background: #D6D6D6;
            border-radius: 1.563vw 1.563vw 1.563vw 1.563vw;
            font-size: 0.833vw;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 2.396vw;
            text-align: center;
            margin-top: 1.042vw;
            &.active,
            &:hover {
              background: var(--baseColor1);
              color: #fff;
            }
            &.text1,
            &.text2 {
              margin-left: 1.563vw;
            }
          }
        }
      }
      .phone-box {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .service-box {
    background-image: url('~@/assets/images/home-image/page28.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    height: auto;
    width: 100vw;
    .service {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.none {
        opacity: 0;
      }
      .title-img {
        width: 35vw;
        height: auto;
        margin-top: 3.854vw;
        margin-left: 4vw;
        margin-bottom: 2.604vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .content {
        .box {
          display: none;
        }
        .box2 {
          display: none;
        }
        .phone-box {
          padding: 0 1.333vw;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item2 {
            flex: none;
            width: 45%;
            margin-bottom: 2.667vw;
            .header {
              font-size: 2.4vw;
              background-color: var(--baseColor1);
              color: #fff;
              border-radius: 3.2vw;
              .title {
                text-align: center;
                height: 6.4vw;
                line-height: 6.4vw;
              }
            }
            .cont {
              margin-top: 1.333vw;
              .text {
                font-size: 1.867vw;
                color: #3d3d3d;
                line-height: 4.267vw;
                text-align: center;
              }
            }
            .btn {
              width: 24.333vw;
              height: 6vw;
              color: #000;
              font-size: 1.867vw;
              line-height: 6vw;
              text-align: center;
              background-color: #D6D6D6;
              border-radius: 3vw;
              margin: 1.333vw auto;
            }
          }
        }
      }
    }
  }
}
</style>
