<template>
  <div class="company-profile-footer ty300" ref="transformRef1">
    <!-- <div class="w content ty300" ref="transformRef1">
      <div class="item-box">
        <div class="item">
          <div class="header">
            <div class="num">{{form.tag1Text}}</div>
            <div class="name">{{ form.tag1Unit }}</div>
          </div>
          <div class="text">{{ form.tag1Name }}</div>
        </div>
        <div class="item">
          <div class="header">
            <div class="num">{{form.tag2Text}}</div>
            <div class="name">{{form.tag2Unit}}</div>
          </div>
          <div class="text">{{form.tag2Name}}</div>
        </div>
        <div class="item">
          <div class="header">
            <div class="num">{{form.tag3Text}}</div>
            <div class="name">{{form.tag3Unit}}</div>
          </div>
          <div class="text">{{form.tag3Name}}</div>
        </div>
        <div class="item">
          <div class="header">
            <div class="num">{{form.tag4Text}}</div>
            <div class="name">{{form.tag4Unit}}</div>
          </div>
          <div class="text">{{form.tag4Name}}</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  reactive, watchEffect, toRefs,
} from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      count: 0,
      transformRef1: null,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 1.2,
        });
      }
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.company-profile-footer {
  width: 100vw;
  height: 14.688vw;
  background-image: url('~@/assets/images/home-image/page52.png');
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .content {
    height: 100%;
  }
  .item-box {
    display: flex;
    align-items: center;
    height: 100%;
    .item {
      flex: 1;
      height: 6.458vw;
      border-right: 1px solid rgba(255,255,255,0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:last-child {
        border-right: none;
      }
      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        .num {
          height: 3.333vw;
          font-size: 2.604vw;
          font-family: Furore-Regular, Furore;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 3.073vw;
          margin-right: 1.042vw;
        }
        .name {
          font-size: 0.938vw;
          color: #fff;
          transform: translateY(0.521vw);
        }
      }
      .text {
        height: 1.094vw;
        font-size: 0.833vw;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.99vw;
        margin-top: 0.833vw;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .company-profile-footer {
    display: none;
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/home-image/page15.png');
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .content {
      height: 100%;
    }
    .item-box {
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      .item {
        flex: auto;
        width: 50%;
        height: 6.458vw;
        border-right: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 2vw;
        &:last-child {
          border-right: none;
        }
        .header {
          display: flex;
          align-items: center;
          justify-content: center;
          .num {
            height: 6.933vw;
            font-size: 3.2vw;
            font-family: Furore-Regular, Furore;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 3.2vw;
            margin-right: 1.042vw;
          }
          .name {
            font-size: 0.938vw;
            color: #fff;
            transform: translateY(0.521vw);
          }
        }
        .text {
          height: 4.267vw;
          font-size: 1.867vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 4.267vw;
          margin-top: 1.333vw;
        }
      }
    }
  }
}
</style>
