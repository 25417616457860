<template>
  <div class="company-profile">
    <div class="w content ty300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/home-image/page04.png" alt="">
      </div>

      <div class="item-box">
        <div class="left">
          <div class="text text1">
            {{ form.briefText }}
          </div>
          <div class="text text2" ref="transformRef4">
            {{ form.briefText2 }}
          </div>
          <div class="text3" ref="transformRef5">
            {{ form.briefText3 }}
          </div>
        </div>
        <div class="right" ref="transformRef6">
          <img src="@/assets/images/home-image/page10.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive, watchEffect, toRefs,
} from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },

  },
  setup(props) {
    const data = reactive({
      count: 0,
      transformRef1: null,
      transformRef3: null,
      transformRef4: null,
      transformRef5: null,
      transformRef6: null,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
      }
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.company-profile {
  padding-bottom: 4.167vw;
  overflow: hidden;
  background-image: url('~@/assets/images/home-image/page13.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  .content {
    .title-img {
      width: 14.063vw;
      height: 6.667vw;
      margin-top: 3.125vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-box {
      display: flex;
      height: 25.521vw;
      .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        .text {
          font-size: 0.833vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 1.875vw;
          padding-left: 2.083vw;
          padding-right: 2.083vw;
          text-indent: 1.667vw;
        }
        .text1 {
          margin-bottom: 1.563vw;
          margin-top: 1.979vw;
        }
        .text2 {
          margin-bottom: 0.938vw;
        }
        .text3 {
          width: 100%;
          flex: 1;
          font-size: 0.833vw;
          text-indent: 1.667vw;
          padding: 1.875vw 2.083vw 0vw;
          background-color: #9E835A;
          color: #fff;
          line-height: 1.875vw;
        }
      }
      .right {
        height: 100%;
        width: 34.635vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .company-profile {
    padding-bottom: 4.167vw;
    overflow: hidden;
    background-image: url('~@/assets/images/home-image/page13.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    .content {
      .title-img {
        width: 30vw;
        height: auto;
        margin-top: 3.125vw;
        margin-left: 4vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .item-box {
        display: flex;
        height: auto;
        .left {
          flex: 1;
          display: flex;
          flex-direction: column;
          .text {
            font-size: 1.867vw;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 4.267vw;
            padding: 0;
            margin: 0 1.333vw;
            text-indent: 3.733vw;
          }
          .text1 {
            margin-bottom: 1.563vw;
            margin-top: 1.979vw;
          }
          .text2 {
            margin-bottom: 0.938vw;
          }
          .text3 {
            width: 100%;
            flex: 1;
            font-size: 1.867vw;
            text-indent: 3.733vw;
            padding: 1.875vw 4vw;
            background-color: #9E835A;
            color: #fff;
            line-height: 4.267vw;
          }
        }
        .right {
          display: none;
          height: 100%;
          width: 34.635vw;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
</style>
