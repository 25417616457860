<template>
  <cks-combined-route @active="refreshActive">
    <div class="index-home">
      <div class="fullpage">
        <!-- 第一屏 -->
        <div class="section">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" :class="`swiper-slide${index + 1}`" v-for="(item, index) in bannerTextList" :key="index">
                <img :src="item" alt="">
              </div>
            </div>
          </div>
          <div class="swiper-container2">
            <div class="swiper-wrapper">
              <div class="swiper-slide" :class="`swiper-slide${index + 1}`" v-for="(item, index) in bannerTextList" :key="index">
                <img :src="item" alt="">
              </div>
            </div>
          </div>
          <div class="search ty300" ref="searchRef">
            <div class="msg">
              <img src="@/assets/images/home-image/page51.png" alt="">
            </div>
            <div class="input-box">
              <input @keyup.enter="getPage" v-model="value" placeholder="请输入城市或仓库名称" class="inp" type="text">
              <div class="list">
                <div class="list-item curr" :class="{'none': !item.id}" @click="detail(item.id)" v-for="item in list" :key="item.id">{{ item.parkTitle }}</div>
              </div>
            </div>
            <div class="btn curr" @click="getPage">
              <img src="@/assets/images/home-image/page50.png" alt="">
            </div>
          </div>
        </div>
        <!-- 企业简介 -->
        <company-profile ref="companyProfileRef" :isView="companyProfileView" :bannerText="bannerText" :form="form" />
        <company-profile-footer ref="companyProfileFooterRef" :isView="companyProfileFooterView" :form="form" />
        <!-- 全链路服务体系 -->
        <service ref="serviceRef" :isView="serviceView"/>
        <!-- 新闻动态 -->
        <news ref="newsRef" :isView="newsView"/>
      </div>
    </div>
  </cks-combined-route>
</template>

<script>
import {
  nextTick,
  onMounted, onUnmounted, reactive, toRefs,
} from 'vue';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import CompanyProfile from './components/company-profile.vue';
import CompanyProfileFooter from './components/company-profile-footer.vue';
import service from './components/service.vue';
import news from './components/news.vue';
import { isElementInViewport, itemGsap } from './js/usePage';
import apis from '@/request/apis';
import { useRouter } from 'vue-router';

export default {
  components: {
    CompanyProfile,
    CompanyProfileFooter,
    service,
    news,
  },
  setup() {
    const router = useRouter();
    const data = reactive({
      pageCount: 4,
      companyProfileRef: null,
      serviceRef: null,
      newsRef: null,
      companyProfileFooterRef: null,
      companyProfileFooterView: false,
      companyProfileView: false,
      serviceView: false,
      newsView: false,
      form: {},
      bannerTextList: [],
      value: '',
      laoding: false,
      list: [],
      searchRef: null,
    });

    const initSwiper = () => {
      data.swiper = new Swiper('.swiper-container', {
        effect: 'cube',
        grabCursor: true,
        loop: true,
        speed: 1000,
        cubeEffect: {
          shadow: false,
        },
        autoplay: {
          disableOnInteraction: false,
        },
      });
      data.swiper2 = new Swiper('.swiper-container2', {
        grabCursor: true,
        loop: true,
        speed: 1000,
        autoplay: {
          disableOnInteraction: false,
        },
      });
    };

    const scroll = () => {
      data.companyProfileView = isElementInViewport(data.companyProfileRef.$el);
      data.companyProfileFooterView = isElementInViewport(data.companyProfileFooterRef.$el);
      data.serviceView = isElementInViewport(data.serviceRef.$el);
      data.newsView = isElementInViewport(data.newsRef.$el);
    };
    let app = null;

    const getDetail = async () => {
      const res = await apis.home.getDetail();
      data.bannerTextList = res.bannerText.split(',');
      data.form = res;
      itemGsap(data.searchRef, {
        y: 0,
        opacity: 1,
        duration: 1,
      });
      nextTick(() => {
        initSwiper();
      });
    };
    const appClick = (e) => {
      if (data.searchRef && !data.searchRef.contains(e.target)) {
        data.list = [];
      }
    };
    onMounted(async () => {
      scroll();
      app = document.querySelector('#app');
      app.addEventListener('scroll', scroll);
      app.addEventListener('click', appClick);
      await getDetail();
    });
    onUnmounted(() => {
      app.removeEventListener('scroll', scroll);
      app.removeEventListener('click', appClick);
      app.scrollTop = 0;
    });

    const getPage = async () => {
      if (!data.value) {
        data.list = [];
        return;
      }
      if (data.loading) return;
      try {
        data.loading = true;
        const res = await apis.operate.getPage({
          current: 1,
          size: 5,
          conditions: JSON.stringify([{ name: 'parkTitle', opr: 'like', value: data.value }]),
        });
        const { records } = res;
        if (records.length) {
          data.list = records;
        } else {
          data.list = [{ parkTitle: '暂无数据...' }];
        }
      } finally {
        data.loading = false;
      }
    };

    const detail = (id) => {
      if (!id) return;
      router.push({
        name: 'operateDetail',
        params: {
          id,
          isIndex: 'Y',
        },
      });
    };
    return {
      ...toRefs(data),
      getPage,
      detail,
    };
  },
};
</script>

<style scoped lang="scss">
.index-home {
  // height: 100vh;
  position: relative;
  .fullpage {
    overflow: hidden;
    width: 100%;
    transition: transform 1s ease;
    .section {
      height: 36.979vw;

      width: 100%;
      position: relative;
      .search {
        position: absolute;
        font-size: 1.146vw;
        bottom: 4.167vw;
        z-index: 2;
        width: 60.052vw;
        height: 4.688vw;
        left: 50%;
        transform: translate(-50%, 300px);
        display: flex;
        .input-box {
          flex: 1;
          height: 100%;
          position: relative;
        }
        .inp {
          width: 100%;
          height: 100%;
          outline: none;
          border: none;
          padding-left: 4.167vw;
          font-size: 1.042vw;
        }
        .list {
          position: absolute;
          width: 100%;
          .list-item {
            width: 100%;
            height: 3.125vw;
            background: #fff;
            padding-left: 1.667vw;
            line-height: 3.125vw;
            color: #333333;
            font-size: 0.938vw;
            &:hover {
              background: var(--baseColor1);
              color: #fff;
            }
            &.none:hover {
              background: #fff;
              color: #333333;
            }
          }
        }
        .msg {
          width: 1.563vw;
          height: 1.719vw;
          position: absolute;
          top: 50%;
          left: 1.667vw;
          transform: translateY(-50%);
          z-index: 3;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .btn {
          width: 5.208vw;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--baseColor1);
          img {
            width: 2.396vw;
            height: 2.396vw;
            display: block;
          }
        }
      }
    }
    .swiper-container {
      width: 100vw;
      height: 36.979vw;
      position: relative;
    }
    .swiper-container2 {
      display: none;
    }

    .swiper-slide {
      background-color: #fff;
      transform-style: preserve-3d;
      transition: transform 0.6s;
      font-size: 30px;
      color: #fff;
      text-align: center;
      img {
        width: 100vw;
        height: 36.979vw;
        object-fit: cover;
      }
    }

  }
}
@media screen and (max-width: 768px) {
  .index-home {
    // height: 100vh;
    position: relative;
    .fullpage {
      overflow: hidden;
      width: 100vw;
      transition: transform 1s ease;
      .section {
        height: calc(100vw/2.742);
        width: 100vw;
        position: relative;
        .search {
          position: absolute;
          font-size: 1.146vw;
          bottom: 2.333vw;
          z-index: 2;
          width: 70vw;
          height: 6.688vw;
          left: 50%;
          transform: translate(-50%, 300px);
          display: flex;
          .input-box {
            flex: 1;
            height: 100%;
            position: relative;
          }
          .inp {
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            padding-left: 6.167vw;
            font-size: 1.6vw;
          }
          .list {
            position: absolute;
            width: 100%;
            .list-item {
              width: 100%;
              height: 4.267vw;
              background: #fff;
              padding-left: 1.667vw;
              line-height: 4.267vw;
              color: #333333;
              font-size: 1.6vw;
              &:hover {
                background: var(--baseColor1);
                color: #fff;
              }
              &.none:hover {
                background: #fff;
                color: #333333;
              }
            }
          }
          .msg {
            width: 2.563vw;
            height: 2.719vw;
            position: absolute;
            top: 50%;
            left: 1.667vw;
            transform: translateY(-50%);
            z-index: 3;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          .btn {
            width: 8.208vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--baseColor1);
            img {
              width: 3.396vw;
              height: 3.396vw;
              display: block;
            }
          }
        }
      }
      .swiper-container {
        display: none;
      }
      .swiper-container2 {
        width: 100vw;
        // height: 30vh;
        height: calc(100vw/2.742);
        position: relative;
        display: block;
      }

      .swiper-slide {
        background-color: #fff;
        transform-style: preserve-3d;
        transition: transform 0.6s;
        font-size: 30px;
        color: #fff;
        text-align: center;
        img {
          width: 100vw;
          height: 100%;
          object-fit: fill;
        }
      }

    }
  }
}
</style>
