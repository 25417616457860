<template>
  <div class="w news">
    <div class="left tx-300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/home-image/page06.png" alt="">
      </div>
      <div :class="{'active': activeIndex === 1}" class="curr btn" @click="navClick(1, true)">
        <img class="img1" src="@/assets/images/home-image/page55.png"/>
        <img class="img2" src="@/assets/images/home-image/page56.png"/>
      </div>
      <div :class="{'active': activeIndex === 2}" class="curr btn" @click="navClick(2, true)">
        <img class="img1" src="@/assets/images/home-image/page54.png"/>
        <img class="img2" src="@/assets/images/home-image/page53.png"/>
      </div>
    </div>
    <div class="right">
      <div class="big curr tx1200" ref="transformRef2" @click="detail(obj.id)">
        <img :src="obj.titlePic" alt="">
        <div class="date">{{ obj.lastPubDate }}</div>
        <div class="title">{{ obj.title }}</div>
        <div class="text">{{ obj.content }}</div>
      </div>
      <div class="min-box">
        <div class="item curr" :class="[`tx${300 * (index + 1)}`]" v-for="(item, index) in list" :key="item.id" ref="itemsRef"  @click="detail(item.id)">
          <div class="date-box">
            <div class="num">{{ getTime(1, item.lastPubDate) }}</div>
            <div class="date">{{ getTime(3, item.lastPubDate) }}</div>
          </div>
          <div class="content-box">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="phone-box">
      <div class="title-img">
        <img src="@/assets/images/home-image/page06.png" alt="">
      </div>
      <div class="min-box" ref="itemsRef5">
        <div class="big" v-for="(item) in list" :key="item.id" @click="detail(item.id)">
          <img :src="item.titlePic" alt="">
          <div class="date">{{ item.lastPubDate }}</div>
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  nextTick,
  reactive, toRefs, watchEffect,
} from 'vue';
import { itemGsap } from '@/views/index/js/usePage';
import apis from '@/request/apis';
import { useRouter } from 'vue-router';

export default {
  props: {
    currentPage: {
      type: Number,
      default: 0,
    },
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const data = reactive({
      flag: false,
      itemsRef: [],
      count: 0,
      transformRef1: null,
      transformRef2: null,
      conditions: [],
      pagination: {
        current: 1,
        size: 5,
      },
      list: [],
      obj: {},
      activeIndex: 1,
    });

    const load = async () => {
      const res = await apis.receptionNewsCenter.getPage({ ...data.pagination, conditions: data.conditions });
      const obj = res.records[0];
      data.obj = obj;
      data.list = res.records.slice(1, 5);
      data.list = data.list.filter(Boolean);
      if (data.flag) {
        nextTick(() => {
          data.itemsRef.forEach((item, index) => {
            itemGsap(item, {
              x: 0,
              opacity: 1,
              duration: 1 + index * 0.1,
              ease: 'none',
            });
          });
        });
      }
    };

    const navClick = (index, flag = false) => {
      data.activeIndex = index;
      data.flag = flag;
      data.conditions = JSON.stringify([{ name: 'newsType', opr: 'eq', value: index - 1 }]);
      data.pagination = {
        current: 1,
        size: 5,
      };
    };
    navClick(1);

    watchEffect(() => {
      if (data.pagination) {
        load();
      }
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          x: 0,
          opacity: 1,
          duration: 0.5,
        });
        itemGsap(data.transformRef2, {
          x: 0,
          opacity: 1,
          duration: 1,
        });
        data.itemsRef.forEach((item, index) => {
          itemGsap(item, {
            x: 0,
            opacity: 1,
            duration: 1 + index * 0.1,
            ease: 'none',
          });
        });
      }
    });

    const getTime = (type, time) => {
      time = time || '';
      if (type === 1) {
        return time?.split('-')?.[2];
      }
      if (type === 2) {
        return time;
      }
      return `${time.split('-')[0]}/${time.split('-')[1]}`;
    };

    const detail = (id) => {
      router.push({
        name: 'receptionNewsCenterDetail',
        params: {
          id,
          isIndex: 'Y',
        },
      });
    };
    return {
      ...toRefs(data),
      navClick,
      getTime,
      detail,
    };
  },
};
</script>

<style scoped lang="scss">
.news {
  padding-top: 6.354vw;
  display: flex;
  height: 40.729vw;
  .left {
    margin-right: 5.208vw;
    transition: opacity 1s;
    .title-img {
      width: 12.292vw;
      height: 6.25vw;
      // margin-top: 3.646vw;
      margin-bottom: 3.125vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .btn {
      width: 13.333vw;
      height: 3.646vw;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.875vw;
      img {
        width: 5vw;
        height: 1.25vw;
        display: block;
      }
      .img2 {
        display: none;
      }
      &.active {
        background-color: var(--baseColor1);
        color: #fff;
        .img2 {
          display: block;
        }
        .img1 {
          display: none;
        }
      }
    }
  }
  .right {
    display: flex;
    flex: 1;
    transition: opacity 1s;
    .big {
      margin-right: 4.167vw;
      width: 24.896vw;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 20.104vw;
        object-fit: cover;
        transition: 0.5s;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
      .date {
        color: #999999;
        height: 1.875vw;
        line-height: 1.875vw;
        margin-top: 0.833vw;
        margin-bottom: 0.625vw;
      }
      .title {
        font-size: 1.25vw;
        max-height: 3.125vw;
        font-family: Tahoma-Regular, Tahoma;
        font-weight: 400;
        color: #000000;
        line-height: 1.563vw;
        margin-bottom: 0.521vw;
        overflow: hidden;
      }
      .text {
        font-size: 0.729vw;
        max-height: 2.083vw;
        overflow: hidden;
        font-family: Tahoma-Regular, Tahoma;
        font-weight: 400;
        color: #333333;
        line-height: 1.042vw;
        margin-top: 0.417vw;
      }
    }
    .min-box {
      flex: 1;
      .item {
        height: 6.25vw;
        display: flex;
        padding: 1.354vw;
        padding-bottom: 0.521vw;
        transition: box-shadow 0.5s, background-color 0.5s, border-bottom-color 0.5s;
        border-bottom: 1px solid #E8E8E8;
        opacity: 0;
        margin-bottom: 1.042vw;
        &:nth-child(1) {
          transform: translateX(300px);
        }
        &:nth-child(2) {
          transform: translateX(600px);
        }
        &:nth-child(3) {
          transform: translateX(900px);
        }
        &:nth-child(4) {
          transform: translateX(1200px);
        }
        &:hover {
          background-color: #fff;
          box-shadow: 0.104vw 0.104vw 0.417vw 0.104vw #ccc;
          border-bottom: 0.521vw solid var(--baseColor1);
          padding-bottom: 0;
        }
        .date-box {
          margin-right: 1.771vw;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .num {
          font-size: 2.5vw;
          font-family: Source Han Serif SC-Bold, Source Han Serif SC;
          font-weight: bold;
          color: #064A6C;
          line-height: 1.875vw;
          // margin-bottom: 0.521vw;
        }
        .date {
          font-size: 0.833vw;
          font-family: Tahoma-Regular, Tahoma;
          font-weight: 400;
          color: #9B9B9B;
          line-height: 1.875vw;
        }

        .content-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 1.042vw;
            font-family: Tahoma-Regular, Tahoma;
            font-weight: 400;
            color: #000000;
            line-height: 1.875vw;
            height: 1.875vw;
            overflow: hidden;
          }
          .text {
            font-size: 0.729vw;
            height: 2.031vw;
            overflow: hidden;
            font-family: Tahoma-Regular, Tahoma;
            font-weight: 400;
            color: #333333;
            line-height: 1.875vw;
          }
        }
      }
    }
  }
  .phone-box {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .news {
    padding-top: 6.354vw;
    display: flex;
    height: auto;
    .left {
      display: none;
    }
    .right {
      display: none;
    }
    .phone-box {
      display: block;
      padding: 0 2.667vw;
      width: 100vw;
      .title-img {
        width: 30vw;
        height: auto;
        margin-left: 4vw;
        margin-bottom: 3.125vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .big {
        margin-bottom: 20px;
        border-radius: 0.8vw;
        overflow: hidden;
        margin-right: 0;
        width: auto;
        height: 50vw;
        background-color: #efefef;
        img {
          display: block;
          width: 100%;
          height: 35vw;
          object-fit: cover;
        }
        .date {
          color: #a8a8a8;
          height: auto;
          line-height: 4.267vw;
          margin-top: 0.833vw;
          margin-bottom: 0.625vw;
          font-size: 1.867vw;
          margin-left: 2vw;
          margin-right: 2vw;
        }
        .title {
          font-size: 1.867vw;
          max-height: 4.867vw;
          font-family: Tahoma-Regular, Tahoma;
          font-weight: 400;
          color: #000000;
          line-height: 4.267vw;
          margin-bottom: 0.521vw;
          overflow: hidden;
          margin-left: 2vw;
          margin-right: 2vw;
        }
        .text {
          font-size: 1.6vw;
          max-height: 3.933vw;
          overflow: hidden;
          font-family: Tahoma-Regular, Tahoma;
          font-weight: 400;
          color: #333333;
          line-height: 3.733vw;
          margin-top: 0.417vw;
          margin-left: 2vw;
          margin-right: 2vw;
        }
      }
    }
  }
}
</style>
